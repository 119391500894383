import React from 'react'
import loadable from '@loadable/component'

const HubspotQuote = loadable(() => import('../common/hubspot-quote'))
const LiteYoutubeEmbedHere = loadable(() => import('../common/lite-youtube-embed-here'))

const PageFirewallSetupSection = () => (
  <>
    <div className="xl:hidden w-full xl:w-1/2 mx-auto">
      <HubspotQuote />
    </div>

    <div className="bg-white w-full border-b">
      <div className="w-11/12 max-w-screen-xl flex flex-col-reverse xl:flex-row items-center justify-center mx-auto my-12">
        <div className='w-full lg:w-1/2 p-4'>
          <h2 className='text-3xl md:text-4xl text-site-lightblue font-bold mb-6'>IT Security for Small Business</h2>
          <p className='text-lg text-gray-800 mb-4'>There are a lot of questions that need to be addressed when talking about cyber security:</p>
          <ul className='list-disc mb-6 ml-8'>
            <li className='text-lg text-gray-800 mb-4'>Are all firewalls created equally?</li>
            <li className='text-lg text-gray-800 mb-4'>Would you like to segment your network for security purposes?</li>
            <li className='text-lg text-gray-800 mb-4'>Do you have remote users that need to securely access your network?</li>
            <li className='text-lg text-gray-800 mb-4'>Would you like to link two sites together or Create Specific Guest policies, real time sandboxing to prevent malware and ransomware?</li>
          </ul>
          <p className='text-lg text-gray-800'>Our team can design and implement firewall solutions that are tailored to your specific business needs with security at the highest priority.</p>
        </div>

        <div className='w-full lg:w-1/2 p-4'>
          <LiteYoutubeEmbedHere youtubeID="bhW9vPJXWEY" />
        </div>
      </div>
    </div>
  </>
)

export default PageFirewallSetupSection